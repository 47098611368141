import React, { useState } from 'react';
import styled from 'styled-components';
import Joi from 'joi';
import { joiResolver } from "@hookform/resolvers/joi";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import CryptoJS from 'crypto-js';
import {
  hasRole,
  requestStatus,
  systemSettingsApi,
  useAuth,
  revalidatePages,
  usePageTitleHeader,
  useDeepCompareEffect,
} from '@clatter/platform';
import {
  Button,
  Checkbox,
  FormButtons,
  FormControl,
  FormMessage,
  Loader,
  messageTypes,
  PasswordValidatorStatus,
  TextControl,
  useDocumentTitle,
} from '@clatter/ui';
import {
  fetchPages,
  fetchMicrosites,
  fetchPageTemplates,
  updateMicrosite,
} from '../../store';
import SiteMaker from '../../components/SiteMaker/SiteMaker';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { pageFromStore } from '../fromStore';
import { selectAllPageTemplates } from '../../store/page-templates.slice';
import { selectPagesEntities } from '../../store/pages.slice';
import { userRolesMap } from '../../constants';
import routes, { documentTitleMap } from '../../routes/routes';
import { isSiteComplete, joiCustomValidators } from "../../helpers";

const PASSWORD_MIN_LENGTH = 10;
const SITES_HOST = process.env.NX_SITES_HOST;
const pageBaseUrl = `${SITES_HOST}/sites`;
const revalidateUrl = `${SITES_HOST}/api/revalidate`;

const StyledPublish = styled.div`
  .site-urls {
    background: #fff;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 16px;
    display: inline-block;
    margin-bottom: 16px;

    h3 {
      padding: 0;
      margin: 0;
    }

    .page {
      margin-top: 8px;

      &:first-child {
        margin-top: 0;
      }

      .page-title {
        font-size: 14px;
      }

      .page-url {
        font-size: 12px;
      }
    }
  }

  .require-password-miniform {
    width: 300px;
    margin-bottom: 16px;

    .password-inputs {
      padding: 16px 0;
    }
  }
`;

const StyledSpacer = styled.div`
  height: 15px;
`;

const validators = [
  {
    errorKey: joiCustomValidators.validateMinLength.errorKey,
    text: `Password must be ${PASSWORD_MIN_LENGTH} characters`,
  },
  {
    errorKey: joiCustomValidators.validateUppercase.errorKey,
    text: 'Password must have a capital letter',
  },
  {
    errorKey: joiCustomValidators.validateLowercase.errorKey,
    text: 'Password must have a lower-case letter',
  },
  {
    errorKey: joiCustomValidators.validateSpecialChar.errorKey,
    text: 'Password must have a symbol (#?!@$%^&*-)',
  },
  {
    errorKey: joiCustomValidators.validateNumber.errorKey,
    text: 'Password must have a number',
  },
  {
    errorKey: joiCustomValidators.validateNoSpaces.errorKey,
    text: 'Password must not have spaces',
  },
  {
    errorKey: joiCustomValidators.validateExclusion.errorKey,
    text: 'Password cannot include client name',
  },
];

const passwordFieldErrorMessage = 'Invalid password';

const generateValidatorsSchema = (clientName) => {
  return Joi.object({
    landing_page: Joi.any(),
    requirePassword: Joi.bool(),
    password: Joi.when(Joi.ref('/requirePassword'), {
      is: true,
      then: Joi.string()
        .custom((value, helpers) => joiCustomValidators.validateMinLength.handler(value, helpers, PASSWORD_MIN_LENGTH))
        .custom(joiCustomValidators.validateUppercase.handler)
        .custom(joiCustomValidators.validateLowercase.handler)
        .custom(joiCustomValidators.validateNumber.handler)
        .custom(joiCustomValidators.validateSpecialChar.handler)
        .custom(joiCustomValidators.validateNoSpaces.handler)
        .custom((value, helpers) => joiCustomValidators.validateExclusion.handler(value, helpers, clientName))
        .required(),
      otherwise: Joi.any().strip(), // Strip password field if requirePassword is not true
    }),
    repeatPassword: Joi.when(Joi.ref('/requirePassword'), {
      is: true,
      then: Joi.string()
        .valid(Joi.ref('password')) // Validate repeatPassword to match password
        .required()
        .messages({
          'any.only': 'Passwords do not match',
          'any.required': 'Passwords do not match',
        }),
      otherwise: Joi.any().strip(), // Strip repeatPassword field if requirePassword is not true
    }),
  }).messages({
    'string.empty': passwordFieldErrorMessage,
    'customPassword.minLength': passwordFieldErrorMessage,
    'customPassword.uppercase': passwordFieldErrorMessage,
    'customPassword.lowercase': passwordFieldErrorMessage,
    'customPassword.number': passwordFieldErrorMessage,
    'customPassword.specialChar': passwordFieldErrorMessage,
    'customPassword.exclusion': passwordFieldErrorMessage,
    'customPassword.noSpaces': passwordFieldErrorMessage,
  });
};

const Publish = () => {
  useDocumentTitle(documentTitleMap.publish)
  const dispatch = useDispatch();
  const { siteId } = useParams();
  const { user, activeUser, isLoading } = useAuth();

  //region SELECTORS
  const loading = useSelector(
    ({ pageTemplates, pages, microsites }) =>
      pageTemplates.loadingStatus === requestStatus.pending ||
      pages.loadingStatus === requestStatus.pending ||
      microsites.loadingStatus === requestStatus.pending,
  );
  const [revalidateInflight, setRevalidateInflight] = useState(false);

  const pageEntities = useSelector(selectPagesEntities);
  const pageTemplates = useSelector(selectAllPageTemplates);

  const currentMicrosite = useSelector((state) => {
    if (!siteId || !state.microsites.entities[siteId]) {
      return;
    }

    return {
      ...state.microsites.entities[siteId],
      pages: state.microsites.entities[siteId].pages?.map((page) =>
        pageFromStore(page, pageTemplates, pageEntities[page.id]),
      ),
    };
  });
  //endregion

  const {
    formState: { errors, isValid, dirtyFields },
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      password: '',
      repeatPassword: '',
      requirePassword: true,
    },
    resolver: joiResolver(generateValidatorsSchema(currentMicrosite?.client_name), { abortEarly: false }),
    criteriaMode: 'all' // This ensures that errors from all fields are passed to the PasswordValidatorStatus component
  });

  const isLandingPageChecked = watch('landing_page') === 'true';
  const isSiteValid = isSiteComplete(currentMicrosite, currentMicrosite?.pages);

  const { renderPageTitleHeader } = usePageTitleHeader({
    currentMicrosite: currentMicrosite,
    pageTitle: "Publish your microsite",
    routes: routes,
  });

  const requirePassword = watch('requirePassword');

  const landingPage = currentMicrosite?.pages?.find(
    (page) => page?.templateName === 'Landing Page',
  );

  const handlePublish = async (formData) => {
    const siteName = currentMicrosite.name;
    const password = formData.password;
    // @todo we ideally use a distinct, randomly-generated salt
    // we should also ideally use >80,000 iterations and PBKDF2
    // but we're doing everything else right *except* hash-stretching
    const salt = CryptoJS.SHA256(siteName).toString();
    const site_password_hash = CryptoJS.SHA256(salt + password).toString();

    const updatedMicrosite = {
      id: siteId,
      password_hash: requirePassword ? site_password_hash : null,
      published: true,
      landing_page: isLandingPageChecked,
    };

    if (!landingPage && updatedMicrosite.landing_page) {
      alert(
        'No "Landing Page" found. Please check if it is available on sites list above.',
      );
    }

    if (landingPage && updatedMicrosite.landing_page) {
      try {
        await systemSettingsApi.updateSystemProfile({
          landing_page_url: `${pageBaseUrl}/${currentMicrosite.name}/${landingPage.name}`,
        });
        alert(
          'We are setting up your landing page. It may take up to 15 mins.',
        );
      } catch (error) {
        alert(`We were unable to set landing page. Reason: ${error.message}`);
      }
    }

    return dispatch(updateMicrosite({
      ...updatedMicrosite,
      c_updated_by: activeUser.email,
    }));
  };

  const handleUnpublish = () => {
    const updatedMicrosite = {
      id: siteId,
      published: false,
      landing_page: false,
      password_hash: null,
      c_updated_by: activeUser.email,
    };

    dispatch(updateMicrosite(updatedMicrosite));

    setValue('landing_page', '');
    setValue('password', '', { shouldValidate: true });
    setValue('repeatPassword', '', { shouldValidate: true });
  };

  const showPasswordFields =
    typeof currentMicrosite?.published === 'boolean' &&
    !currentMicrosite?.published &&
    requirePassword;

  const handleSetAsLandingPage = () => {
    if (
      !isLandingPageChecked &&
      window.confirm(
        'Setting this template as the landing page takes several minutes and cannot be undone.',
      )
    ) {
      return setValue('landing_page', 'true');
    }

    setValue('landing_page', '');
  };

  const renderButtons = () => {
    if (currentMicrosite?.published) {
      return <Button type="button" onClick={() => handleUnpublish()}>Unpublish</Button>;
    }

    // @todo correctly update isPublishable state then change to
    // const enablePublish = currentMicrosite.isPublishable
    const enablePublish = isSiteValid;
    const errorMessage =  !isSiteValid
      ? { message: 'Some blocks are invalid. Please update them before publishing the microsite.'}
      : null;

    return (
      <>
        {landingPage && hasRole(userRolesMap.admin, user) && (
          <Checkbox
            disabled={requirePassword || !enablePublish || !isValid}
            text="Set as Landing Page"
            onChange={handleSetAsLandingPage}
            checked={isLandingPageChecked}
          />
        )}
        <FormControl error={errorMessage}>
          <FormButtons>
            <Button
              disabled={!enablePublish || !isValid}
              type="button"
              onClick={() => {
                handleSubmit(handleFormSubmit)();
              }}
            >
              Publish
            </Button>
          </FormButtons>
        </FormControl>
      </>
    );
  };

  const handleFormSubmit = async (formData) => {
    await handlePublish(formData);

    // revalidate pages
    setRevalidateInflight(true);

    await revalidatePages({
      currentMicrosite: currentMicrosite,
      revalidateUrl: revalidateUrl,
    });

    setRevalidateInflight(false);
  };

  //region EFFECTS
  useDeepCompareEffect(() => {
    dispatch(fetchPages());
    dispatch(fetchMicrosites({ user: user }));
    dispatch(fetchPageTemplates());
  }, [dispatch, user]);

  // let's properly set requirePassword checkbox state...
  useDeepCompareEffect(() => {
    if (currentMicrosite?.id) {
      reset({ requirePassword: currentMicrosite?.published  ? currentMicrosite?.password_hash : true });
    }
  }, [currentMicrosite])
  //endregion

  if (isLoading) {
    return <div>Loading authorization...</div>;
  }

  if (!loading && (!currentMicrosite || !currentMicrosite.pages)) {
    return <p>Couldn't load microsite</p>;
  }

  return (
    <SiteMaker site={currentMicrosite}>
      {(loading || revalidateInflight) && <Loader />}
      <StyledPublish>

        { renderPageTitleHeader() }

        <h3 className="page-header">URLs</h3>
        <div className="site-urls">
          {currentMicrosite?.pages?.map((page) => (
            <div className="page" key={page?.id}>
              <div className="page-title">{page?.title}</div>
              <div className="page-url">
                {currentMicrosite?.published ? (
                  <a
                    href={`${pageBaseUrl}/${currentMicrosite?.name}/${page?.title}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {`${pageBaseUrl}/${currentMicrosite?.name}/${page?.title} `}
                    <OpenInNewIcon />
                  </a>
                ) : (
                  `${pageBaseUrl}/${currentMicrosite?.name}/${page?.title}`
                )}
              </div>
            </div>
          ))}
        </div>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="require-password-miniform">
            <input
              defaultValue={currentMicrosite?.landing_page || false}
              type="hidden"
              {...register('landing_page')}
            />
            <Checkbox
              disabled={currentMicrosite?.published}
              text="Require Password"
              {...register('requirePassword')}
            />
            {showPasswordFields && (
              <div className="password-inputs">
                <FormControl label="Password" error={errors?.password}>
                  <TextControl
                    type="password"
                    autoComplete="false"
                    {...register('password')}
                    onChange={(e) => {
                      setValue('password', e.target.value, {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: true,
                      });
                      trigger('repeatPassword');
                    }}
                  />
                </FormControl>
                <FormControl
                  label="Re-enter password"
                  error={errors?.repeatPassword}
                >
                  <TextControl
                    type="password"
                    autoComplete="false"
                    {...register('repeatPassword', {
                      validate: (value) => {
                        const password = watch('password');

                        if (value !== password) {
                          return 'Password and Re-enter password do not match';
                        }

                        return true;
                      },
                    })}
                  />
                </FormControl>
                <PasswordValidatorStatus
                  errors={errors?.password?.types}
                  dirtyFields={dirtyFields}
                  validators={validators}
                />
              </div>
            )}
          </div>
          {showPasswordFields && (
            <>
              <FormMessage
                message={{
                  type: messageTypes.info,
                  text: 'Please save this password somewhere safe. Once the site is published and you leave this page, you\'ll no longer have access to this password. If you lose or forget the password, please "unpublish" then "publish" for a new password.',
                }}
              />
              <StyledSpacer />
            </>
          )}
          {renderButtons()}
        </form>
      </StyledPublish>
    </SiteMaker>
  );
};

export default Publish;
