import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {
  fetchMicrosites,
  fetchPageTemplates,
  fetchPages,
  fetchResources,
  updatePage,
} from '../../store';
import SiteMaker from '../../components/SiteMaker/SiteMaker';
import {
  Dialog,
  Button,
  StepCard,
  StepCardHeader,
  StepCardTitle,
  StepCardAction,
  StepCardContent,
  FormControl,
  Loader,
} from '@clatter/ui';
import {
  requestStatus,
  useAuth,
  useDeepCompareEffect,
  useIsComponentMounted,
  usePageTitleHeader,
} from '@clatter/platform';
import ResourcePicker from '../../components/ResourcePicker/ResourcePicker';
import {
  pageFromStore,
  resourceFromStore,
  resourceCategoryFromStore,
} from '../fromStore';
import { quickLinksTableColumns } from '../ComponentsList/mocks';
import ResourceForm from '../../components/Admin/Resources/ResourceForm';
import SiteMakerContentHead from '../../components/SiteMaker/SiteMakerContentHead';
import { getNextPage, isSiteComplete } from '../../helpers';
import SiteMakerActions from '../../components/SiteMaker/SiteMakerActions';
import PreviewButton from '../../components/SiteMaker/PreviewButton';
import Page from '../../components/Page/Page';
import { selectAllPageTemplates } from '../../store/page-templates.slice';
import { selectPagesEntities } from '../../store/pages.slice';
import {
  selectAllResources,
  selectResourcesEntities,
} from '../../store/resources.slice';
import { selectAllResourceCategories } from '../../store/resource-categories.slice';
import useMsmPageTitle from '../../hooks/useMsmPageTitle';
import routes from "../../routes/routes";

// the problem we have with our current pattern of selectors is the combination of:
// 1. we refer to the microsites selector to get current pages. this is dependent on
//    the microsites slice being updated whenever a page is updated.
// 2. we rely on the pages selector subscription to force re-render when the pages
//    slice is updated, but the page slice update doesn't change the microsites slice
// effectively, whenever we update a page, the microsites site entry is stale and should
// be refreshed. but it isn't. we rely on an effect (post - render!) to dispatch a new
// fetch of the microsites, but these function components see the stale page value in
// the microsites entry.
//
// the core *requirement* is that the *current* page is guaranteed fresh
// this requires a subscription to the pages slice - specifically, to the current page
// element of that slice. so, we introduced a currentPage selector to subscribe
// specifically to that element.
//
// for the context rail, the requirement is that the currentPages array is fresh

const LinkResourcesBlockConfig = ({ history, match }) => {
  // set page title for GA4
  useMsmPageTitle();

  const { user, isLoading } = useAuth();
  const isComponentMounted = useIsComponentMounted();
  let { pageId } = match.params;

  // parse params
  pageId = parseInt(pageId);

  const [showResourcesDialog, setShowResourcesDialog] = useState(false);

  const toggleShowResourcesDialog = () => {
    setShowResourcesDialog(!showResourcesDialog);
  };

  const {
    control,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    getValues,
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const handleFormSubmit = async (formData) => {
    if (isDirty) {
      await dispatch(
        updatePage({
          id: currentPage.id,
          ...formData,
        }),
      );
    }
    history.push(getNextPage(currentMicrosite, currentMicrosite.pages));
  };

  const onPreviewClick = async () => {
    const formData = getValues();
    await dispatch(
      updatePage({
        id: currentPage.id,
        ...formData,
      }),
    );
    // reset form "dirty" state so further "preview"
    // clicks will not trigger the page update
    reset(formData);
  };

  // while this is *necessary* in that if a page is being rendered
  // without anything in the store, this effect dispatches all the
  // necessary api calls, what about the case when everything is
  // fresh? this dispatches all requests even if store is fresh.
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPages());
    dispatch(fetchMicrosites({ user: user }));
    dispatch(fetchPageTemplates());
    dispatch(fetchResources());
    window.scrollTo(0, 0);
  }, [dispatch, user]);

  const loading = useSelector(
    ({ pages, pageTemplates, microsites, resources }) =>
      pages.loadingStatus === requestStatus.pending ||
      pageTemplates.loadingStatus === requestStatus.pending ||
      microsites.loadingStatus === requestStatus.pending ||
      resources.loadingStatus === requestStatus.pending,
  );

  const pageTemplates = useSelector(selectAllPageTemplates);

  const resources = useSelector(selectAllResources).map(resourceFromStore);
  const pageEntities = useSelector(selectPagesEntities);

  const currentMicrosite = useSelector((state) => {
    if (state.pages.ids.indexOf[pageId] === -1) {
      return;
    }

    const siteId = state.microsites.ids.find((micrositeId) =>
      state.microsites.entities[micrositeId].pages.some(
        (page) => page.id === pageId,
      ),
    );

    if (!siteId || !state.microsites.entities[siteId]) {
      return;
    }

    return {
      ...state.microsites.entities[siteId],
      pages: state.microsites.entities[siteId].pages?.map((page) =>
        pageFromStore(page, pageTemplates, pageEntities[page.id]),
      ),
    };
  });

  const currentPage = pageEntities[pageId] || null;

  const { renderPageTitleHeader } = usePageTitleHeader({
    currentPage: currentPage,
    currentMicrosite: currentMicrosite,
    pageTitle: "Select Quick Links Resources",
    routes: routes,
  });

  const resourceCategories = useSelector(selectAllResourceCategories).reduce(
    (acc, curr) => {
      const category = resourceCategoryFromStore(curr);
      acc.push({
        value: category.id,
        label: category.categoryName,
      });

      return acc;
    },
    [],
  );

  useDeepCompareEffect(() => {
    if (isComponentMounted) {
      reset({
        quick_links: currentPage?.quick_links || [],
      });
    }
  }, [reset, currentPage?.quick_links, isComponentMounted]);

  if (isLoading) {
    return <div>Loading authorization...</div>;
  }

  if (
    !loading &&
    (!currentMicrosite || !currentMicrosite.pages || !currentPage)
  ) {
    return <p>Couldn't load current page</p>;
  }

  const redirectToPublish = () => {
    history.push(`/${currentMicrosite.id}/publish`);
  };

  const renderButtons = () => (
    <>
      <Button disabled={!isValid} type="submit">
        {isDirty ? 'Save Resources and continue' : 'Continue'}
      </Button>
      <PreviewButton
        siteName={currentMicrosite?.name}
        pageName={currentPage?.name}
        onPreviewClick={isDirty ? onPreviewClick : true}
      />
      <Button
        disabled={isDirty || !isSiteComplete(currentMicrosite, currentMicrosite?.pages)}
        onClick={redirectToPublish}
      >
        Publish
      </Button>
    </>
  );

  return (
    <>
      {loading && <Loader />}
      <Page>
        <SiteMaker site={currentMicrosite}>

          { renderPageTitleHeader() }

          <SiteMakerContentHead
            subhead="Upload resources from your computer, and/or select other available resources. A preview of your featured resources will appear below."
          />

          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <StepCard>
              <StepCardHeader step={1}>
                <StepCardTitle text="Add available resources" />
                <StepCardAction
                  label="Add new resource"
                  icon={AddOutlinedIcon}
                  onClick={toggleShowResourcesDialog}
                />
              </StepCardHeader>
              <StepCardContent>
                <FormControl error={errors.quick_links}>
                  <Controller
                    name="quick_links"
                    render={({ field: { onChange, value } }) => (
                      <ResourcePicker
                        onChange={onChange}
                        options={resources}
                        tableColumns={quickLinksTableColumns}
                        value={value}
                      />
                    )}
                    control={control}
                    defaultValue={currentPage?.quick_links || []}
                  />
                </FormControl>
              </StepCardContent>
            </StepCard>
            <SiteMakerActions renderButtons={renderButtons} />
          </form>
          <Dialog
            title="Add resource"
            open={showResourcesDialog}
          >
            <ResourceForm
              categories={resourceCategories}
              onSuccess={toggleShowResourcesDialog}
              onClose={toggleShowResourcesDialog}
            />
          </Dialog>
        </SiteMaker>
      </Page>
    </>
  );
};

export default LinkResourcesBlockConfig;
