import React, { useEffect, useMemo } from 'react';
import Joi from "joi";
import { useParams, useHistory, generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import {
  msmPageTemplatesMap,
  useAuth,
  useDeepCompareEffect,
  useDeepCompareMemo,
  useIsComponentMounted,
  usePageTitleHeader,
} from '@clatter/platform';
import {
  Button,
  Loader,
  StepCard,
  StepCardContent,
  StepCardHeader,
  StepCardTitle,
} from '@clatter/ui';
import {
  fetchMicrosites,
  fetchPageTemplates,
  fetchPages,
  fetchBios,
  updatePage,
} from '../../store';
import SiteMaker from '../../components/SiteMaker/SiteMaker';
import { pageFromStore, bioFromStore } from '../fromStore';
import { getNextPage, isSiteComplete } from '../../helpers';
import BioPicker from '../../components/BioPicker/BioPicker';
import SiteMakerActions from '../../components/SiteMaker/SiteMakerActions';
import PreviewButton from '../../components/SiteMaker/PreviewButton';
import { selectAllPageTemplates } from '../../store/page-templates.slice';
import routes from '../../routes/routes';
import useMsmPageTitle from "../../hooks/useMsmPageTitle";
import { selectPagesEntities } from '../../store/pages.slice';
import {joiResolver} from "@hookform/resolvers/joi";

const biosFormSchema = Joi.object({
  bios: Joi.array().min(1)
}).messages({
  'array.min': 'Please add at least one bio item.',
})

// at the moment, the *BlockConfig pages are at urls that
// contain only the :pageId.
// so in order to set the currentMicrosite, we have to
// consult the microsites slice to determine it.
// an alternative is for us to parameterize the urls with
// /:siteid/:pageId/ so these pages have both params
const BiosBlockConfig = () => {
  // set page title for GA4
  useMsmPageTitle();

  const history = useHistory();
  const { user, isLoading } = useAuth();
  const isComponentMounted = useIsComponentMounted();
  let { pageId } = useParams();

  // parse params
  pageId = parseInt(pageId);

  const {
    control,
    formState: { isDirty, isValid },
    handleSubmit,
    getValues,
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: joiResolver(biosFormSchema),
  });

  const handleFormSubmit = async (formData) => {
    if (isDirty) {
      await dispatch(
        updatePage({
          id: currentPage.id,
          ...formData,
        }),
      );
    }
    history.push(getNextPage(currentMicrosite, currentMicrosite.pages));
  };

  const onPreviewClick = async () => {
    const formData = getValues();
    await dispatch(
      updatePage({
        id: currentPage.id,
        ...formData,
      }),
    );
    // reset form "dirty" state so further "preview"
    // clicks will not trigger the page update
    reset(formData);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPages());
    dispatch(fetchMicrosites({ user: user }));
    dispatch(fetchPageTemplates());
    dispatch(fetchBios());
    window.scrollTo(0, 0);
  }, [dispatch, user]);

  const loading = useSelector(
    (state) =>
      state.pageTemplates.loadingStatus === 'loading' ||
      state.microsites.loadingStatus === 'loading' ||
      state.pages.loadingStatus === 'loading' ||
      state.bios.loadingStatus === 'loading',
  );

  const pageEntities = useSelector(selectPagesEntities);
  const pageTemplates = useSelector(selectAllPageTemplates);

  const bios = useSelector((state) =>
    Object.values(state.bios.entities).map(bioFromStore),
  );

  const currentMicrosite = useSelector((state) => {
    if (state.pages.ids.indexOf[pageId] === -1) {
      return;
    }

    const siteId = state.microsites.ids.find((micrositeId) =>
      state.microsites.entities[micrositeId].pages.some(
        (page) => page.id === pageId,
      ),
    );

    if (!siteId || !state.microsites.entities[siteId]) {
      return;
    }

    return {
      ...state.microsites.entities[siteId],
      pages: state.microsites.entities[siteId].pages?.map((page) =>
        pageFromStore(page, pageTemplates, pageEntities[page.id]),
      ),
    };
  });

  const currentPage = useDeepCompareMemo(() => {
    const pageEntity = { ...pageEntities[pageId] };

    if (pageEntity) {
      pageEntity.bios = pageEntity.bios ? pageEntity.bios.map(bioFromStore) : [];
    }

    return pageEntity;
  }, [pageEntities]);

  useDeepCompareEffect(() => {
    if (isComponentMounted) {
      reset({
        bios: currentPage?.bios || [],
      });
    }
  }, [reset, currentPage?.bios, isComponentMounted]);

  const { renderPageTitleHeader } = usePageTitleHeader({
    currentPage: currentPage,
    currentMicrosite: currentMicrosite,
    pageTitle: "Select Bios to Include",
    routes: routes,
  });

  if (isLoading) {
    return <div>Loading authorization...</div>;
  }

  const redirectToPublish = () => {
    history.push(generatePath(routes.publishSite, { siteId: currentMicrosite.id }));
  };

  const renderButtons = () => (
    <>
      <Button disabled={!isValid} type="submit">
        {isDirty ? 'Save Bios and continue' : 'Continue'}
      </Button>
      <PreviewButton
        siteName={currentMicrosite?.name}
        pageName={currentPage?.name}
        onPreviewClick={isDirty ? onPreviewClick : true}
      />
      <Button
        disabled={isDirty || !isSiteComplete(currentMicrosite, currentMicrosite?.pages)}
        onClick={redirectToPublish}
      >
        Publish
      </Button>
    </>
  );

  if (
    !loading &&
    (!currentMicrosite || !currentMicrosite.pages || !currentPage)
  ) {
    return <p>Couldn't load current page</p>;
  }

  const biosLimit = useMemo(() => {
    switch (currentPage?.templateName) {
      case msmPageTemplatesMap.STATE_GOVERNMENT_OPTUM:
      case msmPageTemplatesMap.PARTNER_SITE_WITH_TWO_CONTENT_AND_TEAM_OPTUM_RX:
        return 3;
      default:
        return 50;
    }
  }, [currentPage?.templateName]);

  return (
    <>
      {loading && <Loader />}
      <SiteMaker site={currentMicrosite}>

        { renderPageTitleHeader() }

        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <StepCard>
            <StepCardHeader step={1}>
              <StepCardTitle text="Add bios" />
            </StepCardHeader>
            <StepCardContent>
              <Controller
                name="bios"
                render={({ field: { onChange, value } }) => (
                  <BioPicker
                    onChange={onChange}
                    options={bios}
                    value={value}
                    max={biosLimit}
                  />
                )}
                control={control}
                defaultValue={[]}
              />
            </StepCardContent>
          </StepCard>
          <SiteMakerActions renderButtons={renderButtons} />
        </form>
      </SiteMaker>
    </>
  );
};

export default BiosBlockConfig;
