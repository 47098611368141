import getAxiosWithStrapi from './strapiFactory';

const resourcesApi = {
  fetchResources: () =>
    getAxiosWithStrapi({
      url: `/resources`,
      method: 'get',
      params: {
        'pagination[limit]': -1,
        sort: 'title:asc',
        populate: '*',
      },
    }),
};

export default resourcesApi;
