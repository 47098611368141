import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Page from '../../components/Page/Page';
import SiteMakerContentHead from '../../components/SiteMaker/SiteMakerContentHead';
import SiteMaker from '../../components/SiteMaker/SiteMaker';
import { pageFromStore } from '../fromStore';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMicrosites, fetchPages, fetchPageTemplates } from '../../store';
import { msmPageTemplatesMap, requestStatus, useAuth, usePageTitleHeader } from '@clatter/platform';
import { selectAllPageTemplates } from '../../store/page-templates.slice';
import { Loader } from '@clatter/ui';
import ContentForm from '../../components/Content/ContentForm';
import { selectPagesEntities } from '../../store/pages.slice';
import { v4 as uuidv4 } from 'uuid';
import routes from "../../routes/routes";

const RAILS_ORDER_POSITION = ['left', 'center'];

export const defaultRailItem = {
  description: '',
  showButton: false,
  buttonText: '',
  buttonUrl: '',
};

const Content = () => {
  const dispatch = useDispatch();
  const { user, isLoading } = useAuth();
  let { pageId } = useParams();

  // parse params
  pageId = parseInt(pageId);

  useEffect(() => {
    dispatch(fetchPages());
    dispatch(fetchMicrosites({ user: user }));
    dispatch(fetchPageTemplates());
    window.scrollTo(0, 0);
  }, [dispatch, user]);

  const loading = useSelector(
    ({ contentRails, pages, pageTemplates, microsites }) =>
      pages.loadingStatus === requestStatus.pending ||
      pageTemplates.loadingStatus === requestStatus.pending ||
      microsites.loadingStatus === requestStatus.pending ||
      contentRails.loadingStatus === requestStatus.pending,
  );

  const pageEntities = useSelector(selectPagesEntities);
  const pageTemplates = useSelector(selectAllPageTemplates);
  const currentPage = pageEntities[pageId];

  const currentMicrosite = useSelector((state) => {
    if (state.pages.ids.indexOf[pageId] === -1) {
      return;
    }

    const siteId = state.microsites.ids.find((micrositeId) =>
      state.microsites.entities[micrositeId].pages.some(
        (page) => page.id === pageId,
      ),
    );

    if (!siteId || !state.microsites.entities[siteId]) {
      return;
    }

    return {
      ...state.microsites.entities[siteId],
      pages: state.microsites.entities[siteId].pages?.map((page) =>
        pageFromStore(page, pageTemplates, pageEntities[page.id])
      ),
    };
  });

  const { renderPageTitleHeader } = usePageTitleHeader({
    currentPage: currentPage,
    currentMicrosite: currentMicrosite,
    pageTitle: "Add Content",
    routes: routes,
  });

  const contentRailsData = useMemo(() => {
    // if there is a saved data sort it and parse items
    if (currentPage?.content_rails?.length) {
      return [...currentPage.content_rails]
        .sort((prevRail, nextRail) =>
          RAILS_ORDER_POSITION.indexOf(prevRail.position) >
          RAILS_ORDER_POSITION.indexOf(nextRail.position)
            ? 1
            : -1,
        )
        .map((railItem) => ({
          ...railItem,
          items: JSON.parse(railItem?.items || '[]'),
        }));
    }
    // set initial data for a new page
    const railsInitialData = (() => {
      switch (currentPage?.page_template?.template) {
        case msmPageTemplatesMap.EVENTS_OPTUM:
          return {
            leftHeadline: 'Events',
            centerHeadline: 'Important information',
            leftItems: [
              { ...defaultRailItem, id: uuidv4() },
              { ...defaultRailItem, id: uuidv4() },
            ],
          };
        case msmPageTemplatesMap.STATE_GOVERNMENT_OPTUM:
        case msmPageTemplatesMap.PARTNER_SITE_WITH_TWO_CONTENT_AND_TEAM_OPTUM_RX:
          return {
            leftHeadline: 'Content Box #1',
            centerHeadline: 'Content Box #2',
            leftItems: [
              { ...defaultRailItem, id: uuidv4() },
              { ...defaultRailItem, id: uuidv4() },
            ],
            centerItems: [
              { ...defaultRailItem, id: uuidv4() },
              { ...defaultRailItem, id: uuidv4() },
            ],
          };
        default:
          return {
            leftHeadline: '',
            centerHeadline: '',
            leftItems: [],
            centerItems: [],
          };
      }
    })();

    return [
      {
        headline: railsInitialData?.leftHeadline || '',
        position: 'left',
        description: '',
        showButton: false,
        buttonText: '',
        buttonUrl: '',
        items: railsInitialData?.leftItems || [],
      },
      {
        position: 'center',
        headline: railsInitialData.centerHeadline || '',
        description: '',
        showButton: false,
        buttonText: '',
        buttonUrl: '',
        items: railsInitialData?.centerItems || [],
      },
    ];
  }, [currentPage?.page_template?.template, currentPage?.content_rails, pageId]);

  if (isLoading) {
    return <div>Loading authorization...</div>;
  }

  if (
    !loading &&
    (!currentMicrosite || !currentMicrosite.pages || !currentPage)
  ) {
    return <p>Couldn't load current page</p>;
  }

  return (
    <Page>
      {loading && <Loader />}
      <SiteMaker site={currentMicrosite}>

        { renderPageTitleHeader() }

        <SiteMakerContentHead
          subhead="Please complete the following steps and click the “continue” button below"
        />

        {currentPage && (
          <ContentForm
            railsData={contentRailsData}
            currentMicrosite={currentMicrosite}
            currentPage={currentPage}
            pageId={pageId}
          />
        )}
      </SiteMaker>
    </Page>
  );
};

export default Content;
