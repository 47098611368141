import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { generatePath, NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isRejected } from '@reduxjs/toolkit';
import {
  Edit as EditIcon,
  Publish as PublishIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  TransferWithinAStation as TransferWithinAStationIcon
} from '@mui/icons-material';
import {
  DataGridPremium,
  IconButton,
  Button,
  TableSearchFilter,
  DataGridBulkEditButton,
  dataGridBulkEditButtonTypes,
  useDocumentTitle,
} from '@clatter/ui';
import {
  hasRole,
  useAuth,
  useNotices,
  noticesTypes,
  formatDateTime,
  useEventTracking,
  eventRequestTypes,
  usePageTitleHeader,
  useDeepCompareMemo,
  useDeepCompareEffect,
} from '@clatter/platform';
import {
  fetchCobrandLogos,
  fetchMicrosites,
  fetchPages,
  fetchPageTemplates,
  setIsCreatingMicrosite,
} from '../../store';
import {
  cloneMicrosite,
  createMicrosite,
  deleteMicrosite,
  deleteMicrosites,
  selectAllMicrosites,
  selectMicrositesEntities,
} from '../../store/microsites.slice';
import { OwnershipTransferModal } from "../../components";
import { isSiteComplete } from '../../helpers';
import { pageFromStore } from '../fromStore';
import { selectAllPageTemplates } from '../../store/page-templates.slice';
import { userRolesMap } from '../../constants';
import routes, { documentTitleMap } from '../../routes/routes';
import { selectPagesEntities } from "../../store/pages.slice";

const StyledMyMicrosites = styled.div`
  max-width: 1280px;
  margin: 0 auto;

  .my-microsites-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      margin: 0;
    }
  }
`;

const StyledMicrositeName = styled.div`
  cursor: pointer;
`;

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const MyMicrosites = () => {
  useDocumentTitle(documentTitleMap.microsites);

  const history = useHistory();
  const dispatch = useDispatch();
  const { user, isLoading } = useAuth();
  const { addNotice } = useNotices();
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState([]);
  const { trackEvent } = useEventTracking();

  const [ownershipTransferModalState, setOwnershipTransferModalState] = useState({
    opened: false,
    microsite: null,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const userIsAdmin = useDeepCompareMemo(() => hasRole(userRolesMap.admin, user), [user, userRolesMap]);
  const userIsContentAdmin = useDeepCompareMemo(() => hasRole(userRolesMap.contentAdmin, user), [user, userRolesMap]);
  const enableOwnershipTransferAction = userIsAdmin;

  useEffect(() => {
    dispatch(fetchPageTemplates());
    dispatch(fetchPages());
    dispatch(fetchMicrosites({ user: user }));
    dispatch(fetchCobrandLogos());
    dispatch(setIsCreatingMicrosite(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const loading = useSelector(
    (state) =>
      state.microsites.loadingStatus === 'loading' ||
      state.pages.loadingStatus === 'loading',
  );

  const microsites = useSelector(selectAllMicrosites);
  const micrositesEntities = useSelector(selectMicrositesEntities);

  const pageTemplates = useSelector(selectAllPageTemplates);
  const pagesEntities = useSelector(selectPagesEntities);

  const { renderPageTitleHeader } = usePageTitleHeader({
    pageTitle: documentTitleMap.microsites,
    routes: routes,
  });

  // @todo this useMemo is a recommended (by someone) technique (the MIT guys I think)
  // I'm not convinced that it's appropriate here, but I'm giving it a try.
  const micrositesMemo = React.useMemo(
    () =>
      microsites.map((microsite) => ({
        ...microsite,
        pages: (microsite.pages || []).map((page) =>
          pageFromStore(page, pageTemplates),
        ),
      })),
    [microsites, pageTemplates],
  );

  const handleDeleteItem = async (event) => {
    if (window.confirm('Are you sure you want to remove this microsite?')) {

      const response = await dispatch( deleteMicrosite({ micrositeId: parseInt(event.currentTarget.dataset.id) }));

      if (isRejected(response)) {
        addNotice({
          message: response?.payload?.message || `Error deleting microsite "${micrositeToDelete.name}"`,
          type: 'error',
          title: 'Error',
        });
        return;
      }

      addNotice({
        message: response?.payload?.message || `Microsite has been successfully deleted`,
        type: 'success',
        title: 'Success',
      });
    }
  };

  const handleDeleteItems = (items, clearSelection) => async () => {
    if (
      window.confirm(`Are you sure you want to remove ${items.length} selected microsites?`)
    ) {
      const micrositesToDelete = items.map((micrositeId) =>
        micrositesMemo.find(({ id }) => id === micrositeId),
      );

      await dispatch(deleteMicrosites(micrositesToDelete));
      clearSelection();
    }
  };

  const handleCloneItem = async (event) => {
    const micrositeId = parseInt(event?.currentTarget?.dataset?.id);
    const micrositeToClone = micrositesEntities?.[micrositeId]

    const response = await dispatch(cloneMicrosite({ micrositeId: micrositeId }));

    if (isRejected(response)) {
      addNotice({
        message: response?.payload?.message || 'Error cloning microsite!',
        type: 'error',
        title: 'Error',
      });
      return;
    }

    addNotice({
      message: `Microsite "${micrositeToClone?.name}" has been successfully ` +
        `cloned${response?.payload?.name ? ` with name "${response.payload.name}."` : '.'}`,
      type: 'success',
      title: 'Success',
    });
  };

  const handlePublishItem = (event) => {
    history.push(generatePath(routes.publishSite, { siteId: parseInt(event.currentTarget.dataset.id) }));
  };

  const handleEditItem = (event) => {
    history.push(generatePath(routes.siteSettings, { siteId: parseInt(event.currentTarget.dataset.id) }));
  };

  const handleNavigateToPreview = (event) => {
    const microsite = micrositesMemo.find(
      ({ id }) => id === parseInt(event.currentTarget.dataset.id),
    );

    window.open(
      `${process.env.NX_PREVIEW_HOST}/api/preview?secret=MY_SECRET_TOKEN&slug=sites/${microsite.name}/${microsite.pages[0].name}`,
      '_blank',
      'noopener',
    );
  };

  const handleNewClick = () => {
    dispatch(setIsCreatingMicrosite(true))
    dispatch(createMicrosite(user.email)).then((res) => {
      trackEvent(eventRequestTypes.micrositeCreated, {
        microsite_id: res.payload.id,
        microsite_name: res.payload.name,
      });

      history.push(generatePath(routes.siteSettings, { siteId: res.payload.id }));
    });
  };

  const handleRowClick = (item, event) => {
    // do not call history.push if we click on a link eg. status column
    if (event.target.nodeName !== 'A') {
      history.push(generatePath(routes.siteSettings, { siteId: item.row.id }));
    }
  };

  const micrositesColumns = useDeepCompareMemo(() => {
    let nextMicrositesColumns = [
      {
        field: 'name',
        headerName: 'Microsite Name',
        flex: 3,
        renderCell: (item) => (<StyledMicrositeName>{item.row.name}</StyledMicrositeName>),
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 2,
        renderCell: (item) => {
          if (
            (!item.row.published || userIsContentAdmin) &&
            isSiteComplete(item.row, item.row.pages)
          ) {
            return (
              <NavLink to={`/${item.id}/publish`}>
                {item.row.published ? 'Published' : 'Not Published'}
              </NavLink>
            );
          }

          return item.row.published ? 'Published' : 'Not Published';
        },
        sortComparator: (v1, v2, param1, param2) =>
          param1.api.getCellValue(param1.id, 'published') -
          param2.api.getCellValue(param2.id, 'published'),
      },
      {
        field: 'numPages',
        headerName: '# of Pages',
        flex: 1,
        valueGetter: ({ row }) => row?.pages?.length,
        sortComparator: (v1, v2, param1, param2) =>
          (param1.api.getCellValue(param1.id, 'pages')?.length || 0) -
          (param2.api.getCellValue(param2.id, 'pages')?.length || 0),
      },
      {
        field: 'createdAt',
        headerName: 'Date Created',
        flex: 2,
        valueFormatter: (params) => formatDateTime(params.value),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 2,
        sortable: false,
        disableExport: true,
        renderCell: (item) => (
          <>
            {
              enableOwnershipTransferAction &&
              <IconButton
                onClick={() =>
                  setOwnershipTransferModalState({
                    opened: true,
                    microsite: item?.row,
                  })
                }
                tooltip="Transfer Ownership"
              >
                <TransferWithinAStationIcon/>
              </IconButton>
            }
            <IconButton
              disabled={!item.row?.pages?.length}
              data-id={item.id}
              onClick={handleNavigateToPreview}
              tooltip="Preview"
            >
              <VisibilityOutlinedIcon />
            </IconButton>
            <IconButton
              data-id={item.id}
              onClick={handleEditItem}
              tooltip="Edit"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              disabled={
                !item.row?.pages?.length ||
                !isSiteComplete(item.row, item.row.pages)
              }
              data-id={item.id}
              onClick={handlePublishItem}
              tooltip="Publish"
            >
              <PublishIcon />
            </IconButton>
            <IconButton
              data-id={item.id}
              onClick={handleCloneItem}
              tooltip="Clone"
            >
              <FileCopyOutlinedIcon />
            </IconButton>
            <IconButton
              data-id={item.id}
              onClick={handleDeleteItem}
              tooltip="Delete"
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </>
        ),
      },
    ];

    if (userIsAdmin) {
      nextMicrositesColumns = [
        ...nextMicrositesColumns.slice(0, 1),
        {
          field: 'owner',
          headerName: 'Owner',
          flex: 3,
        },
        ...nextMicrositesColumns.slice(1),
      ];
    }

    return nextMicrositesColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsContentAdmin, micrositesMemo, micrositesEntities, pagesEntities]);

  const renderCustomToolbar = ({ selectedItems, clearSelection }) => {
    return (
      <DataGridBulkEditButton
        onClick={handleDeleteItems(selectedItems, clearSelection)}
        type={dataGridBulkEditButtonTypes.delete}
        tooltip="Delete selected"
      />
    );
  };

  const requestSearch = (searchValue) => {
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = micrositesMemo.filter((row) =>
      micrositesColumns.some((col) => {
        if ('valueGetter' in col) {
          return searchRegex.test(col.valueGetter({ row: row }));
        }
        return searchRegex.test(row[col.field]);
      }),
    );
    setRows(filteredRows);
  };


  const handleOwnershipTransferModalClose = (data) => {
    // show corresponding notice after submit
    // skip if user just closed modal
    if (data && data?.status) {
      addNotice({
        message:  data?.message,
        type: data.status === 'success' ? noticesTypes.SUCCESS : noticesTypes.ERROR,
        title: 'Ownership Transfer',
      });
    }

    // clear state
    setOwnershipTransferModalState({ opened: false, microsite: null });
  }

  //region EFFECTS
  useEffect(() => {
    requestSearch(searchText);
  }, [searchText]);

  useDeepCompareEffect(() => {
    setRows(micrositesMemo);
  }, [micrositesMemo]);
  //endregion

  if (isLoading) {
    return <div>Loading authorization...</div>;
  }

  return (
    <>
      <StyledMyMicrosites>
        {renderPageTitleHeader({
          headerActions: <Button onClick={handleNewClick} testId="new-microsite-button">New</Button>,
          secondaryContent:
            <>
              <p>
                Please select New to create a microsite or click the microsite
                name below to edit an existing microsite.
              </p>
              <TableSearchFilter value={searchText} onChange={setSearchText} />
            </>
        })}

        <DataGridPremium
          loading={loading}
          name="msm/microsites"
          onRowClick={handleRowClick}
          renderCustomToolbar={renderCustomToolbar}
          columns={micrositesColumns}
          defaultSortField={{ field: 'created_at', sort: 'desc' }}
          rows={rows}
          selectable
        />
      </StyledMyMicrosites>

      {enableOwnershipTransferAction && (
        <OwnershipTransferModal
          opened={ownershipTransferModalState?.opened}
          microsite={ownershipTransferModalState?.microsite}
          onClose={(data) => handleOwnershipTransferModalClose(data)}
        />
      )}
    </>
  );
};

export default MyMicrosites;
