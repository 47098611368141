import React, { useState } from 'react';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import {
  hasRole,
  useAuth,
  useNotices,
  userRolesMap,
  useRoutesState,
  useDeepCompareMemo,
  usePageTitleHeader,
  useDeepCompareEffect,
  useIsComponentMounted,
  dateRangeGranularityOptions,
} from '@clatter/platform';
import { useDocumentTitle } from '@clatter/ui';
import Page from '../../../components/Page/Page';
import PageviewsFilter, { DEFAULT_SORT_MODEL, PAGE_VIEWS_FILTER_KEY } from './Filters/PageViewsFilters';
import MicrositeStatisticsViewsBySiteTable from './MicrositeStatisticsViewsBySiteTable';
import { pageviewsFilter } from './Filters/pageviewsFilter';
import routes, { documentTitleMap } from '../../../routes/routes';
import { useGetPageViewsByDateQuery } from "../../../store/apiSlice";

const StyledLink = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.linkColor};
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.palette.linkHoverColor};
  }
`;

const StyledTableWrapper = styled.div`
  margin-top: 20px;
`;

const StyledPageContent = styled.div`
  min-width: 300px;
  max-width: calc(100vw - 80px);
  margin: 0 auto;
  padding: 32px 16px;
  min-height: calc(100vh - 64px);
`;

const mapDateRangeToPlausible = (dateRange) => {
  const { value, range } = dateRange || {}

  const startDate = range?.startDate.split(" ")[0]
  const endDate = range?.endDate.split(" ")[0]

  switch (value) {
    case dateRangeGranularityOptions.TODAY.value:
      return 'period=day&keybindHint=D';
    case dateRangeGranularityOptions.PAST_30_DAYS.value:
      return 'period=30d&keybindHint=T';
    case dateRangeGranularityOptions.THIS_MONTH.value:
      return 'period=month&keybindHint=M';
    case dateRangeGranularityOptions.PAST_30_DAYS.value:
      return `month&keybindHint=M&date=${endDate}`;
    case dateRangeGranularityOptions.THIS_YEAR.value:
      return 'period=year&keybindHint=Y';
    default:
      return `period=custom&from=${startDate}&to=${endDate}`
  }
}

const mapGoalToPlausible = (goal) => {
  switch (goal) {
    case 'bio_clicks':
      return 'Bio Click';
    case 'resource_clicks':
      return 'Resource Click';
    case 'video_clicks':
      return 'Video Click';
    default:
      return ''
  }
}

const MicrositeStatisticsView = () => {
  const {documentFileName} = useDocumentTitle(
    documentTitleMap.micrositeStatistics.document,
  );
  const { getRouteStateProperty } = useRoutesState();
  const isComponentMounted = useIsComponentMounted();
  const { addNotice } = useNotices();
  const [_, setFilterValues] = useState();
  const [rows, setRows] = useState([]);
  const { user } = useAuth();
  const hasMsmAdminRole = hasRole(userRolesMap.msmAdmin, user);

  const persistedFormState = getRouteStateProperty(PAGE_VIEWS_FILTER_KEY);

  const { renderPageTitleHeader } = usePageTitleHeader({
    pageTitle: documentTitleMap.micrositeStatistics.page,
    routes: routes,
  });

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: persistedFormState || {
      date: {},
      search_query: '',
      sort_model: DEFAULT_SORT_MODEL,
    },
  });

  const { getValues, watch} = formMethods;

  const dateValue = watch('date');
  const searchQueryValue = watch('search_query');

  const queryParams = useDeepCompareMemo(() => {
    if (!dateValue?.dateRange?.range?.startDate || !dateValue?.dateRange?.range?.endDate) {
      return {};
    }

    return {
      startDate: format(parseISO(dateValue?.dateRange?.range?.startDate), 'yyyy-MM-dd'),
      endDate: format(parseISO(dateValue?.dateRange?.range?.endDate), 'yyyy-MM-dd'),
    }
  }, [dateValue?.dateRange?.range?.startDate, dateValue?.dateRange?.range?.endDate])

  const { data: reportData, error, isLoading } = useGetPageViewsByDateQuery(
    queryParams,
    {
      skip: !queryParams?.startDate || !queryParams?.endDate,
    }
  );

  const handleClick = (microsite, goalName) => {
    const dateRange = mapDateRangeToPlausible(dateValue?.dateRange);

    if (!microsite || !dateRange) {
      return '';
    }

    // note: Plausible expects passed filters to be double url encoded
    const filters = [
      `(contains,page,(${encodeURIComponent(encodeURIComponent(`/sites/${microsite}`))}))`,
    ];

    if (goalName) {
      const normalizedGoalName = mapGoalToPlausible(goalName);

      if (normalizedGoalName) {
        filters.push(`(is,goal,(${encodeURIComponent(encodeURIComponent(normalizedGoalName))}))`);
      }
    }

    window.open(`${generatePath(routes.analytics)}?${dateRange}&filters=(${filters.join(',')})`, '_blank');
  };

    const siteTableColumns = [
      {
        headerName: 'Site',
        field: 'site',
        sortable: true,
        flex: 1,
        renderCell: (params) => {
          if (!hasMsmAdminRole) {
            return params.value;
          }

          return (
            <StyledLink
              onClick={() => handleClick(params.value)}
            >
              {params.value} <OpenInNewIcon style={{fontSize: '1rem'}}/>
            </StyledLink>
          );
        }
      },
      {
        headerName: 'Status',
        field: 'published',
        sortable: true,
        renderCell: ({ value }) => value ? 'Published' : 'Not Published',
        flex: 0.4,
        maxWidth: 150,
      },
      {
        headerName: 'Pageviews',
        field: 'views',
        sortable: true,
        flex: 0.5,
        maxWidth: 150,
      },
      {
        headerName: 'Visitors',
        field: 'visitors',
        sortable: true,
        flex: 0.5,
        maxWidth: 150,
      },
      {
        headerName: 'Resource Clicks',
        field: 'resource_clicks',
        sortable: true,
        flex: 0.5,
        maxWidth: 150,
        renderCell: (params) => {
          if (!hasMsmAdminRole) {
            return params.value;
          }

          return (
            <StyledLink
              onClick={() => handleClick(params?.row?.site, params?.field)}
            >
              {params.value} <OpenInNewIcon style={{fontSize: '1rem', marginLeft: '10px'}}/>
            </StyledLink>
          );
        }
      },
      {
        headerName: 'Bio Clicks',
        field: 'bio_clicks',
        sortable: true,
        flex: 0.5,
        maxWidth: 150,
        renderCell: (params) => {
          if (!hasMsmAdminRole) {
            return params.value;
          }

          return (
            <StyledLink
              onClick={() => handleClick(params?.row?.site, params?.field)}
            >
              {params.value} <OpenInNewIcon style={{fontSize: '1rem', marginLeft: '10px'}}/>
            </StyledLink>
          );
        }
      },
      {
        headerName: 'Video Clicks',
        field: 'video_clicks',
        sortable: true,
        flex: 0.5,
        maxWidth: 150,
        renderCell: (params) => {
          if (!hasMsmAdminRole) {
            return params.value;
          }

          return (
            <StyledLink
              onClick={() => handleClick(params?.row?.site, params?.field)}
            >
              {params.value} <OpenInNewIcon style={{fontSize: '1rem', marginLeft: '10px'}}/>
            </StyledLink>
          );
        }
      },
  ]

  useDeepCompareEffect(() => {
    if (error) {
      addNotice({
        message: [
          'Error during fetching data from the API.',
          error?.data?.message && `Reason: ${error.data.message}`,
        ].join(' '),
        type: 'error',
        title: 'Error',
      })
    }
  }, [error]);

  // set reports data on filter change
  useDeepCompareEffect(() => {
    if (isComponentMounted) {
      setRows(pageviewsFilter({
        data: reportData,
        filters: getValues(),
        tableColumns: siteTableColumns,
      }));
    }
  }, [reportData, searchQueryValue, isComponentMounted]);

  return (
    <Page withPageContent={false}>
      <FormProvider {...formMethods}>
        <StyledPageContent>

          { renderPageTitleHeader() }

          <PageviewsFilter
            disabled={isLoading}
            onFiltersChange={setFilterValues}
          />

          <StyledTableWrapper>
            <MicrositeStatisticsViewsBySiteTable
              isLoading={isLoading}
              rows={rows}
              columns={siteTableColumns}
              exportFileName={`${documentFileName} (Microsite)`}
              name="report/msm-microsite-statistics-views-by-site"
            />
          </StyledTableWrapper>
        </StyledPageContent>
      </FormProvider>
    </Page>
  );
};

export default MicrositeStatisticsView;
