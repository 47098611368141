const routes = {
  index: '/',
  microsites: '/microsites',
  siteSettings: '/:siteId/sitesettings',
  publishSite: '/:siteId/publish',
  pageSettings: '/:pageId/pagesettings',
  pageContent: '/:pageId/pagecontent',
  pageResources: '/:pageId/resources',
  pageResourcesTable: '/:pageId/resources-table',
  pageQuickLinks: '/:pageId/link-resources',
  pageOptionalCopyBlock: '/:pageId/optional-copy-block',
  pageLibraryResources: '/:pageId/library-resources',
  pageVideos: '/:pageId/videos',
  pageVideosTable: '/:pageId/videos-table',
  pageBios: '/:pageId/bios',
  externalLink: '/:pageId/external-link',
  pageFooter: '/:pageId/footer',
  pagePreview: '/:pageId/preview',
  pageBuildPreview: '/:pageId/previewbuild',
  analytics: '/analytics',
  reportsPageviews: '/reports/pageviews',
  micrositeStatistics: '/reports/microsites-statistics',
  reportsMicrositesDetail: '/reports/microsites-detail',
  reportsMicrositesCreate: '/reports/microsites-create',
  reportsMicrositesPageCreate: '/reports/microsites-page-create',
  components: '/components',
  logout: '/logout',
  admin: '/admin',
};

export const documentTitleMap = {
  microsites: 'Project Library',
  admin: 'Admin:Microsite Settings',
  adminLandingPage: 'Admin:LandingPage',
  adminBios: 'Admin:Bios',
  adminBioGroups: 'Admin:Bio Groups',
  adminResources: 'Admin:Resources',
  adminResourceCategories: 'Admin:Resource Categories',
  adminVideos: 'Admin:Videos',
  adminVideoCategories: 'Admin:Video Categories',
  adminHeroImages: 'Admin:Hero Images',
  adminCoBrandLogos: 'Admin:Co Brand Logos',
  adminTemplates: 'Admin:Templates',
  reportPageViews: {
    document: 'Reports:Page Views (GA4)',
    page: 'Pageviews (GA4)',
  },
  micrositeStatistics: {
    document: 'Reports:Pageviews by Microsite',
    page: 'Pageviews by Microsite',
  },
  reportUnusedAssets: {
    document: 'Reports:Unused Assets',
    page: 'Unused Assets',
  },
  reportsMicrositesDetail: {
    document: 'Reports:Microsites Detail',
    page: 'Microsites Detail',
  },
  reportsMicrositesCreate: {
    document: 'Reports:Microsites Created',
    page: 'Microsites Created',
  },
  reportsMicrositesPageCreate: {
    document: 'Reports:Microsites Page Created',
    page: 'Microsites Page Created',
  },
  reportsAssetUsage: {
    document: 'Reports:Asset Usage',
    page: 'Asset Usage',
  },
  siteSettings: 'Site Settings',
  createMicrosite: 'Create Microsite',
  editMicrosite: 'Edit Microsite',
  publish: 'Publish',
  components: 'Components',
};

export default routes;
