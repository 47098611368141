import { msmPageTemplatesMap } from '@clatter/platform';
import { cloneDeep } from 'lodash';
import { generatePath } from 'react-router-dom';
import routes from '../routes/routes';

export const formatFileSize = (fileSize, format = 'KB') => {
  switch (format) {
    case 'KB':
      return `${Math.floor(fileSize / 1000)} KB`;
    case 'MB':
      return `${Math.floor(fileSize / 1000000)} MB`;
    default:
      return `${fileSize} B`;
  }
};

export const compareIdentifiers = (first, second) => {
  const firstType = typeof first;
  const secondType = typeof second;

  if (firstType === secondType) {
    return first === second;
  }

  if (firstType === 'string' && secondType === 'number') {
    return first === second.toString();
  }

  if (firstType === 'number' && secondType === 'string') {
    return first === Number(second);
  }

  return false;
};

export const getCopyName = (name) => {
  if (/ Copy( [0-9]+)?$/.test(name)) {
    const [copyName, copyNumber] = name.split(' Copy');
    const nextCopyNumber = copyNumber ? Number(copyNumber) + 1 : 2;
    return `${copyName} Copy ${nextCopyNumber}`;
  }

  return `${name} Copy`;
};

// temp: working out data structure for "page list" for use by getNextPage()

// NOTE: Important: order of generate path should match order in mockBlocks.js
// config, otherwise it will not keep form order...
const blockConfigListForPage = (page) => {
  switch (page.templateName) {
    case 'Prospect Site':
    case 'Prospect Site UHC':
    case 'Prospect Site UHC - Optum':
    case 'Prospect Site UHC - UMR':
    case 'Prospect Site - Coley':
    case msmPageTemplatesMap.PROSPECT_SITE_UHC_SUREST:
      return [
        generatePath(routes.pageSettings, { pageId: page.id }),
        generatePath(routes.pageResources, { pageId: page.id }),
        generatePath(routes.pageVideos, { pageId: page.id }),
        generatePath(routes.pageFooter, { pageId: page.id }),
      ];

    case msmPageTemplatesMap.LANDING_PAGE_MA:
    case msmPageTemplatesMap.LANDING_PAGE_UHC:
      return [
        generatePath(routes.pageSettings, { pageId: page.id }),
      ];

    case msmPageTemplatesMap.UHC_MAIN:
    case msmPageTemplatesMap.URS_MAIN_MA:
      return [
        generatePath(routes.pageSettings, { pageId: page.id }),
        generatePath(routes.pageResources, { pageId: page.id }),
        generatePath(routes.pageVideos, { pageId: page.id }),
      ];

    case 'Partner Site':
    case 'Partner Site - Coley':
    case 'Partner Site - Optum Rx brand':
    case 'Partner Site - Optum brand':
      return [
        generatePath(routes.pageSettings, { pageId: page.id }),
        generatePath(routes.pageBios, { pageId: page.id }),
        generatePath(routes.pageResources, { pageId: page.id }),
      ];

    case 'Additional Resources with videos':
    case 'Additional Resources with videos - Optum Rx brand':
    case 'Additional Resources with videos - Optum brand':
    case msmPageTemplatesMap.ADDITIONAL_RESOURCES_WITH_VIDEOS_OPTUM_FINANCIAL:
      return [
        generatePath(routes.pageSettings, { pageId: page.id }),
        generatePath(routes.pageVideos, { pageId: page.id }),
        generatePath(routes.pageResources, { pageId: page.id }),
      ]

    case 'Additional Resources':
    case 'Additional Resources - Coley':
    case 'Additional Resources - Optum Rx brand':
    case 'Additional Resources - Optum brand':
    case msmPageTemplatesMap.ADDITIONAL_RESOURCES_OPTUM_FINANCIAL:
    case msmPageTemplatesMap.UHC_ADDITIONAL_RESOURCES:
    case msmPageTemplatesMap.URS_ADDITIONAL_RESOURCES_MA:
      return [
        generatePath(routes.pageSettings, { pageId: page.id }),
        generatePath(routes.pageResources, { pageId: page.id }),
      ]

    case 'Additional Videos':
    case 'Video Template':
    case 'Video Template - Coley':
    case msmPageTemplatesMap.UHC_VIDEO:
    case msmPageTemplatesMap.URS_VIDEO_MA:
      return [
        generatePath(routes.pageVideosTable, { pageId: page.id }),
      ]

    case 'Landing Page':
    case 'Landing Page - Coley':
      return [
        generatePath(routes.pageResourcesTable, { pageId: page.id }),
        generatePath(routes.pageQuickLinks, { pageId: page.id }),
      ];

    case msmPageTemplatesMap.DOWNLOAD_DECKS_UHC:
      return [
        generatePath(routes.pageQuickLinks, { pageId: page.id }),
        generatePath(routes.pageResourcesTable, { pageId: page.id })
      ];

    case 'Payer':
    case msmPageTemplatesMap.PARTNER_SITE_WITH_TWO_CONTENT_AND_ADDITIONAL_INFORMATION_OPTUM_RX:
    case msmPageTemplatesMap.PARTNER_SITE_WITH_TWO_CONTENT_AND_ADDITIONAL_INFORMATION_OPTUM_FINANCIAL:
      return [
        generatePath(routes.pageSettings, { pageId: page.id }),
        generatePath(routes.pageContent, { pageId: page.id }),
        generatePath(routes.pageResources, { pageId: page.id }),
        generatePath(routes.pageFooter, { pageId: page.id }),
      ];

    case msmPageTemplatesMap.LIFE_SCIENCES_OPTUM_BRAND:
    case msmPageTemplatesMap.PROSPECT_SITE_WITH_RESOURCES_AND_LIBRARY_OPTUM_RX:
    case msmPageTemplatesMap.RESOURCE_AND_DOCUMENT_LIBRARY_OPTUM_BRAND:
      return [
        generatePath(routes.pageSettings, { pageId: page.id }),
        generatePath(routes.pageResources, { pageId: page.id }),
        generatePath(routes.pageQuickLinks, { pageId: page.id }),
        generatePath(routes.pageOptionalCopyBlock, { pageId: page.id }),
        generatePath(routes.pageFooter, { pageId: page.id }),
      ];

    case msmPageTemplatesMap.RESOURCES_TEMPLATE_OPTUM:
      return [generatePath(routes.pageResourcesTable, { pageId: page.id })];

    case msmPageTemplatesMap.EVENTS_OPTUM:
      return [
        generatePath(routes.pageSettings, { pageId: page.id }),
        generatePath(routes.pageContent, { pageId: page.id }),
        generatePath(routes.pageOptionalCopyBlock, { pageId: page.id }),
      ];
    case msmPageTemplatesMap.STATE_GOVERNMENT_OPTUM:
    case msmPageTemplatesMap.PARTNER_SITE_WITH_TWO_CONTENT_AND_TEAM_OPTUM_RX:
      return [
        generatePath(routes.pageSettings, { pageId: page.id }),
        generatePath(routes.pageContent, { pageId: page.id }),
        generatePath(routes.pageBios, { pageId: page.id }),
        generatePath(routes.pageOptionalCopyBlock, { pageId: page.id }),
      ];
    case msmPageTemplatesMap.EXTERNAL_LINK:
      return [
        generatePath(routes.externalLink, { pageId: page.id }),
      ];
  }
};

const pageListFromPages = (pages) => pages.map(blockConfigListForPage);

const getPageList = (microsite, pages) =>
  [
    generatePath(routes.siteSettings, { siteId: microsite.id }),
    pageListFromPages(pages),
    generatePath(routes.publishSite, { siteId: microsite.id }),
  ].flat(2);

// assume both history and location are in scope
export const getNextPage = (microsite, pages) => {
  const pageList = getPageList(microsite, pages);
  const currentIdx = pageList.indexOf(window.location.pathname);
  const nextPage = pageList[currentIdx + 1];
  return nextPage;
};

export const arrayMove = (array, from, to) => {
  const clonedArray = cloneDeep(array);
  const startIndex = from < 0 ? clonedArray.length + from : from;

  if (startIndex >= 0 && startIndex < clonedArray.length) {
    const endIndex = to < 0 ? clonedArray.length + to : to;

    const [item] = clonedArray.splice(from, 1);
    clonedArray.splice(endIndex, 0, item);
  }

  return clonedArray;
};

export const nameResourceMap = {
  'Additional Information': 'resources',
  Bios: 'bios',
  Resources: 'resources',
  'Internal Resources': 'resources',
  'Quick Links': 'quick_links',
  Videos: 'video_embeds',
  'Videos Table': 'video_embeds',
  'Content': 'content_rails',
};

export const isBannerComplete = (page) => {
  if (!page.title || !page.variables) {
    return false;
  }

  const variables = JSON.parse(page.variables);
  return variables?.headline && variables?.introCopy;
};

export const isExternalLinkBlockComplete = (page) => {
  return page?.external_link?.trim();
}

export const isFooterComplete = (page) => {
  if (!page.variables) {
    return false;
  }

  const variables = JSON.parse(page.variables);

  return (
    'footerBio' in variables &&
    ('displayName' in variables.footerBio ||
      (Array.isArray(variables.footerBio) && variables.footerBio.length))
  );
};

export const isBlockComplete = (page, block) => {
  switch (block.name) {
    case 'Optional Copy Blocks':
      return true; // this block is optional, so we will always consider it as completed
    case 'Banner':
      return isBannerComplete(page);

    case 'Additional Information':
    case 'Bios':
    case 'Content':
    case 'Resources':
    case 'Internal Resources':
    case 'Quick Links':
    case 'Videos':
    case 'Videos Table':
      return (
        page[nameResourceMap[block.name]] &&
        page[nameResourceMap[block.name]].length
      );

    case 'Footer':
      return isFooterComplete(page);

    case 'External Link':
      return isExternalLinkBlockComplete(page);

    default:
      return false;
  }
};

export const isPageComplete = (page) =>
  page &&
  'blocks' in page &&
  Array.isArray(page.blocks) &&
  page.blocks.length > 0 &&
  page.blocks.every((block) => isBlockComplete(page, block));

// for the moment, we aren't checking any sites setings, just that all pages are complete
export const isSiteComplete = (site, pages) =>
  pages && Array.isArray(pages) && pages.length && pages.every(isPageComplete);

export const isDev = () =>
  window.location.search?.indexOf('dev') !== -1 &&
  (window.location.host?.indexOf('dev') !== -1 ||
    window.location.host?.indexOf('staging') !== -1 ||
    window.location.host?.indexOf('localhost') !== -1);

export const patterns = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/,
};

export const joiCustomValidators = {
  validateMinLength: {
    errorKey: 'customPassword.minLength',
    handler: (value, helpers, limit) => {
      if (!limit) {
        console.error('validateMinLength:: "limit" not provided!');
        return helpers.error('customPassword.minLength', { limit: 0 })
      }
      if (value.length < limit) {
        return helpers.error('customPassword.minLength', { limit: limit });
      }
      return value;
    },
  },
  validateUppercase: {
    errorKey: 'customPassword.uppercase',
    handler: (value, helpers) => {
      if (!/[A-Z]/.test(value)) {
        return helpers.error('customPassword.uppercase');
      }
      return value;
    },
  },
  validateLowercase: {
    errorKey: 'customPassword.lowercase',
    handler: (value, helpers) => {
      if (!/[a-z]/.test(value)) {
        return helpers.error('customPassword.lowercase');
      }
      return value;
    }
  },
  validateSpecialChar: {
    errorKey: 'customPassword.specialChar',
    handler: (value, helpers) => {
      if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value)) {
        return helpers.error('customPassword.specialChar');
      }
      return value;
    }
  },
  validateNumber: {
    errorKey: 'customPassword.number',
    handler: (value, helpers) => {
      if (!/\d/.test(value)) {
        return helpers.error('customPassword.number');
      }
      return value;
    }
  },
  validateNoSpaces: {
    errorKey: 'customPassword.noSpaces',
    handler: (value, helpers) => {
      if (/\s/.test(value)) {
        return helpers.error('customPassword.noSpaces');
      }
      return value;
    }
  },
  validateExclusion: {
    errorKey: 'customPassword.exclusion',
    handler: (value, helpers, clientName) => {
      const pattern = new RegExp(`^((?!${clientName}).)*$`, 'i');
      if (!pattern.test(value)) {
        return helpers.error('customPassword.exclusion', { clientName: clientName });
      }
      return value;
    }
  }
}
